const useMediaQuery = (query: string) => {
  const isMatching = ref(false)

  if (import.meta.client) {
    const mediaQuery = window.matchMedia(query)

    const listener = (event) => {
      isMatching.value = event.matches
    }

    isMatching.value = mediaQuery.matches
    mediaQuery.addEventListener('change', listener)
  }

  return {
    isMatching
  }
}

export default useMediaQuery
