const useIntersectionObserver = (elementRef: Ref<HTMLElement | null>) => {
  const isInViewport: Ref<boolean> = ref(false)
  const isOrWasInViewport: Ref<boolean> = ref(false)
  let intersectionObserver: IntersectionObserver

  onMounted(() => {
    intersectionObserver = new IntersectionObserver((entries) => {
      isInViewport.value = entries[0].isIntersecting
      isOrWasInViewport.value ||= isInViewport.value
    })

    watch(elementRef, (newVal) => {
      if (newVal instanceof HTMLElement) {
        intersectionObserver.observe(newVal)
      }
    }, { immediate: true })
  })

  onBeforeUnmount(() => {
    if (elementRef.value instanceof HTMLElement) {
      intersectionObserver.unobserve(elementRef.value)
      isInViewport.value = false
    }
  })

  return {
    isInViewport,
    isOrWasInViewport
  }
}

export default useIntersectionObserver
